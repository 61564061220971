.single-question-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.question-text-container {
  width: 100%;
  font-size: 1.8em;
  font-weight: bold;
  /* margin-bottom: 20px; */
}

.question-image-container {
  max-width: 100%;
  max-height: 100%;
 /* display: flex; */
  /*justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-grow: 1;
  max-height: 40%;*/
}

.question-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.feedback-and-answers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.feedback {
  /* width: 100%;
  max-width: 500px; */
  /* margin-bottom: 20px; */
  /* padding: 10px; */
 /* border-radius: 8px;
  text-align: center;*/
}

.correct-feedback {
  background-color: rgba(223, 240, 216, 0.9);
  color: #3c763d;
  border: 2px solid #3c763d;
}

.incorrect-feedback {
  background-color: rgba(242, 222, 222, 0.9);
  color: #a94442;
  border: 2px solid #a94442;
}

.answers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: auto;
}

.answers-container button {
  margin: 5px;
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px);
  box-sizing: border-box;
  background-color: white;
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  padding: 12px 24px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answers-container button:hover {
  background-color: #FFFF00; /* Yellow */
}

/* Styles for Free-Text and Drop-Down questions */
.free-text-container {
  width: 100%;
  max-width: 500px;
}

.free-text-container input {
  width: 100%;
  margin-bottom: 10px;
}

.drop-down-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

.drop-down-container select {
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 12px 24px;
  font-size: 16px;
  border: 2px solid black;
  border-radius: 8px;
  box-sizing: border-box;
}

.submit-button {
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #45a049;
}

@media (max-width: 768px) {
  .question-text-container {
    font-size: 1.5em;
  }

  .answers-container button,
  .free-text-container input,
  .drop-down-container select,
  .submit-button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .question-text-container {
    font-size: 1.2em;
  }

  .answers-container button,
  .free-text-container input,
  .drop-down-container select,
  .submit-button {
    font-size: 12px;
    padding: 8px 16px;
  }
}

/*
.draggable {
  cursor: move;
  user-select: none;
}

.drop-target {
  transition: all 0.2s ease;
}

.drop-target.can-drop {
  background-color: rgba(200, 200, 200, 0.5);
}

.drop-target.dragging-over {
  background-color: rgba(150, 150, 150, 0.5);
  transform: scale(1.02);
}
  */