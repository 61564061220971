.get-user-media-error {
    grid-column: 1 / 3;
    align-items: center;
  }
  
  .get-user-media-error h1 {
    color: var(--red-dark);
  }
  
  .get-user-media-error button {
    margin: 1rem 0 1rem 0;
    background: black;
    color: var(--white);
  }
  
  .get-user-media-error a,
  .get-user-media-error a:visited {
    color: white;
    text-decoration: underline;
  }
  
  .get-user-media-error p {
    margin: 0;
  }