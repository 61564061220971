/*
  CSS grid is great for making video layouts.
  For the purposes of this demo, we're keeping things relatively simple.
  If you're interested in dynamic video grids, make sure to check out this blog post:
   https://www.daily.co/blog/add-pagination-to-a-custom-daily-video-chat-app-to-support-larger-meetings/
 */
 .call {
  display: grid;
  padding: 10px;/*5rem;*/
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
  position: relative;
}

.side-by-side-container {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Two equal columns */
  gap: 10px;
  padding: 10px;
  width: 100%;
  max-width: 1600px; /* Prevent getting too wide on large screens */
  margin: 0 auto;
}

@media only screen and (max-width: 900px) {
  .side-by-side-container {
    grid-template-columns: 1fr; /* Stack vertically on smaller screens */
  }
}

.tutor-grid {
  display: grid;
  padding: 10px;/*5rem;*/
  align-items: center;
  justify-items: center;
  grid-template-columns: repeat(auto-fit, minmax(440px, 1fr));
  grid-auto-rows: 1fr;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .call {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-auto-rows: unset;
  }
}

.is-screenshare {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 15px;
}

/*
  When someone is sharing their screen, we want to resize the participants' videos,
  so the biggest screen in our grid is the screen share.
 */
.is-screenshare .self-view {
  width: 240px;
  height: 135px;
}

/* Resize remote participants when a screen is being shared */
.is-screenshare .tile-video {
  width: 240px;
  height: 135px;
}

.info-box {
  background: var(--dark-blue-border);
  color: var(--white);
  width: 480px;
  height: 270px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
}

@media only screen and (max-width: 600px) {
  .info-box {
    width: 240px;
    height: auto;
    padding: 2rem 1rem;
  }
}

.room-url {
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
}

/*-----------------------------------*/
/* my own additions */
/*-----------------------------------*/

.learning-session-layout {
  position: relative;
  width: 100%;

}

.learning-session {
  width: 100%;

}

.floating-video-tile {
  position: absolute;
   top: 0px; 
   right: 0px; 
 /* width: 300px; */  /* adjust size as needed */
 /* height: 200px; */ /* adjust size as needed */
  z-index: 1000;
  pointer-events: none;
}

/* Make the video fit within the container */
.floating-video-tile :global(.tile-video) {
  width: 100%;
  height: 100%;
}

.student-learning-view {
  position: relative;
  margin-bottom: 1rem;
}

.student-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 0.5rem;
  border-radius: 0 0 4px 4px;
}