/* Default scale factor for small screens */
:root {
    --scale-factor: 1.2;
  }

    /* I've currently made the scale factor 1.1 for both small and medium screens, but it may need to be adjusted */
    /* small screens */
    @media (min-width: 200px) {
        :root {
          --scale-factor: 1.1;
        }
      }
  
  /* Medium screens */
  @media (min-width: 768px) {
    :root {
      --scale-factor: 1.1;
    }
  }
  
  /* Large screens */
  @media (min-width: 1024px) {
    :root {
      --scale-factor: 1.4;
    }
  }
  

@media (max-width: 500px) {
  .character-container {
    position: fixed;
    bottom: 8rem;   /* Add this line to position from bottom */
    right: -2rem;    /* Add this line to position from right */
    width: 40vw;  /* Slightly larger on small screens */
    height: 40vw; /* Maintain aspect ratio */
    max-width: 160px; /* Prevent it from getting too large */
    max-height: 160px;
  }
}


@media (min-width: 501px) and (max-width: 1023px) {
    .character-container {
      position: fixed;
      bottom: 6rem;  /* Adjust this value as needed */
      right: -2rem;   /* Adjust this value as needed */
      width: 40vw;
      height: 40vw;
      max-width: 180px;
      max-height: 180px;
    }
  }

/*
@media (min-width: 640px) and (max-width: 1023px) {
  .character-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 22vw;  // Slightly larger on medium screens 
    height: 22vw; // Maintain aspect ratio 
    max-width: 180px; // Prevent it from getting too large 
    max-height: 180px;
  }
}
  */

@media (min-width: 1024px) {
  .character-container {
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    width: 18vw;  /* Original size for larger screens */
    height: 18vw; /* Maintain aspect ratio */
    max-width: 200px; /* Prevent it from getting too large */
    max-height: 200px;
  }
}
