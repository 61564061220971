.progress-bar-container {
    position: relative;
    height: 40px;
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 12px;
    overflow: hidden;
    border: 2px solid black;
    flex-shrink: 0;
}

.progress-bar {
    height: 100%;
    background-color: #76c7c0;
    transition: width 0.3s ease-in-out;
}

.progress-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    line-height: 24px;
    font-weight: bold;
    color: black;
    z-index: 1;
    font-size: 18px;
    overflow: visible;
}
