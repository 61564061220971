.username {
    position: absolute;
    z-index: 2;
    color: /*var(--grey)*/ white;
    background-color: /*var(--dark-blue)*/ black;
    bottom: 0;
    left: 0;
    font-size: 14px;
    padding: 0.2em 0.5em;
  }


/* Smaller username style for student watch mode */
.student-watch-username {
  position: absolute;
  z-index: 2;
  color: white;
  background-color: black;
  bottom: 0;
  left: 0;
  font-size: 8px;          /* Smaller font size */
  padding: 0.1em 0.3em;     /* Smaller padding */
}