.has-hand-raised {
    position: absolute;
    top: 8px;
    left: 8px;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 20px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
    gap: 4px;
    z-index: 50;
  }
  
  /* we use green for both local and remote. leaving them separate for now incase I want to 
  change one of the colours later*/
  
  .has-hand-raised.local {
    background: #25D366;
    color: white;
  }

  .has-hand-raised.remote {
    background: #25D366;
    color: white;
  }
  
  .queue-number {
    font-size: 12px;
    font-weight: bold;
  }