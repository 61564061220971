.tutor-session-tile {
    position: relative;
    width: 440px;
    height: 248px;
    border: 1px solid gray;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    
  }


.floating-video-tile {
    position: absolute;
     bottom: 2px; 
     right: 0px; 
   /* width: 300px; */  /* adjust size as needed */
   /* height: 200px; */ /* adjust size as needed */
    z-index: 1000;
    pointer-events: none;
  }