
/* This is the container that contains the video element */
.tile-video {
  position: relative;
  width: 440px;
  max-width: 440px;
  height: 248px;
  border: 1px solid gray;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.tutor-watch-video {
  position: relative;
  width: 240px;
  height: 135px;
  border: 1px solid gray;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

.student-watch-video {
  position: relative;
  width: 120px;
  height: 67.5px;
  border: 1px solid gray;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  
}

/* New class for larger video tiles */
.large-tile {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  border: 1px solid gray;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.large-tile video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* This is the video element that displays the participant's video 
   this is what ensures ipad and mobile device video remains within the tile and does not overflow */
.tile-video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.student-watch-video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tutor-watch-video video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media only screen and (max-width: 600px) {
  .tile-video {
    width: 240px;
    height: 135px;
  }
}

.self-view {
  width: 480px;
  height: 270px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .self-view {
    width: 240px;
    height: 135px;
  }
}

.no-video {
  background-color: var(--dark-blue-border);
}

.no-video video {
  visibility: hidden;
}

/* Hard-code the self-view size if there's no one else in the call, just to make it align with the info-box */
.self-view.alone {
  width: 480px;
  height: 270px;
}

@media only screen and (max-width: 600px) {
  .self-view.alone {
    width: 240px;
    height: 135px;
  }
}

/* Put screen share front and center in our grid when there is one active */
.tile-screenshare {
  grid-column: 1 / -1;
  grid-row: 1;
}