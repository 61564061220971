.next-question-button {
  position: absolute;
  top: 10px; /* Adjust based on your layout */
  right: 10px; /* Adjust based on your layout */
  z-index: 2; /* Ensure it is above other elements */
  padding: 12px 20px;
  font-size: 16px;
}

.feedback {
  /*position:absolute;*/
  /*bottom: 4rem;*/
  width: 100%;
  max-width: 500px;
  margin: /*20px*/ 0;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  box-sizing: border-box;
  text-align: center;
  z-index: 2;
}

.correct-feedback {
  background-color: #dff0d8;
  color: #3c763d;
  border: 2px solid #3c763d;
}

.incorrect-feedback {
  background-color: #f2dede;
  color: #a94442;
  border: 2px solid #a94442;
}

.question-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  /*padding: 20px;*/
  box-sizing: border-box;
  overflow-y: auto;
  /*border: 1px solid blue;*/

}

.horizontal-center-wrapper {
  display: flex;
  flex-direction: row;  /* horizontal alignment */
  justify-content: center;  /* centers horizontally */
  width: 100%;
}

.question-page-content-wrapper {
  /*border: 1px solid red;*/
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  
  height: calc(100vh - 40px); /* Subtract padding */
  padding-top: 0.5vh;
  position: relative;

 /* max-width: 1200px; */
  margin: 0 auto;
}

.video-style-addy {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 25%;
  height: auto;
  object-fit: cover;
  z-index: 10;
  margin-right: -2rem;
  margin-top: 1rem;
}

.streak-animation {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  width: 25%;
  height: auto;
  object-fit: cover;
  z-index: 10;
  margin-right: -2rem;
  margin-top: -2rem;
}

.question-image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 3vh;
  margin-top: 60px; /* Move the image container down */
  flex-grow: 1;
  max-height: calc(60vh - 5vh - 150px); /* Adjust based on your layout */
  position: relative;
  z-index: 0; /* Ensure image is behind buttons */
}

.question-image {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.question-text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 3vh;
  padding: 0 20px;
  font-size: 2.2em;
  font-weight: bold;
  margin-bottom: 5px;
  /*position: absolute; */
  top: -20px;
  z-index: 1;
}

.answers-container-question-page {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 68%;
  /*margin-top: -70px;*/ /* Move the buttons down */
  z-index: 1; /* Ensure buttons are in front of the image */
}

.answers-container-question-page button {
  margin: 5px;
  flex: 1 1 calc(50% - 10px);
  max-width: calc(50% - 10px); /* Ensure consistent width */
  box-sizing: border-box;
  background-color: white; /* Ensure default background color */
  color: black;
  border: 2px solid black;
  border-radius: 8px;
  text-align: center;
  font-size: 16px;
  padding: 12px 24px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answers-container-question-page button:hover {
  background-color: #ffeb3b; /* Yellow background on hover */
}

.answers-container-question-page button:active {
  background-color: #ffeb3b; /* Ensure active background matches hover */
}

.answers-container-question-page button.correct {
  background-color: #4caf50 !important; /* Green background for correct answer */
  color: white;
}

.answers-container-question-page button.incorrect {
  background-color: #f44336 !important; /* Red background for incorrect answer */
  color: white;
}

@media (max-width: 768px) {
  .question-image-container {
    max-height: calc(50vh - 5vh - 150px);
  }

  .answers-container-question-page button {
    flex: 1 1 calc(100% - 10px); /* Ensure buttons take full width on smaller screens */
    max-width: calc(100% - 10px); /* Ensure consistent width */
  }

  .next-question-button {
    padding: 8px 16px; /* Adjust padding for smaller screens */
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .question-text-container {
    font-size: 2em;
  }

  .streak-animation {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: 35%;
    z-index: 10;
    margin-right: -2rem;
    margin-top: -2rem;
  }

  .video-style-addy {
    bottom: 1rem;
    width: 30%;
    margin-right: -2rem;
    margin-top: 2rem;
  }
}

@media (max-width: 480px) {
  .question-image-container {
    max-height: calc(40vh - 5vh - 150px);
  }

  .answers-container-question-page button {
    flex: 1 1 calc(100% - 10px); /* Ensure buttons take full width on smaller screens */
    max-width: calc(100% - 10px); /* Ensure consistent width */
  }

  .next-question-button {
    padding: 4px 8px; /* Further adjust padding for very small screens */
    font-size: 10px; /* Further adjust font size for very small screens */
  }

  .question-text-container {
    font-size: 1.8em;
  }

  .streak-animation {
    position: fixed;
    bottom: 1rem;
    left: 1rem;
    width: 35%;
    z-index: 10;
    margin-right: -2rem;
    margin-top: -2rem;
  }


  .video-style-addy {
    bottom: 1rem;
    width: 30%;
    margin-right: -2rem;
    margin-top: -4rem;
  }
}

/* For Free-Text question type */
.free-text-container-question-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*margin-top: -90px;*/ /* Adjust this value to move the container up */
  z-index: 1; /* Ensure input box is in front of the image */
}

.free-text-container-question-page input {
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 12px 24px;
  font-size: 16px;
  border: 2px solid black;
  border-radius: 8px;
  box-sizing: border-box;
}

.free-text-container-question-page button {
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  /*background-color: #4caf50;*/
  color: black;
  /*border: 2px solid #4caf50;*/
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px; 
}

.free-text-container-question-page button:hover {
  /*background-color: #388e3c;*/ /* Yellow background on hover */
}

.free-text-container-question-page button:active {
  /*background-color: #388e3c;*/ /* Ensure active background matches hover */
}

/* For Drop-Down-List question type */
.drop-down-container-question-page {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*margin-top: -60px;*/ /* Adjust this value to move the container up */
  z-index: 1; /* Ensure input box is in front of the image */
}

.drop-down-container-question-page select {
  width: 100%;
  max-width: 500px;
  margin: 10px 0;
  padding: 12px 24px;
  font-size: 16px;
  border: 2px solid black;
  border-radius: 8px;
  box-sizing: border-box;
}

.drop-down-container-question-page button {
  margin-top: 10px;
  padding: 12px 24px;
  font-size: 16px;
  /*background-color: #4caf50;*/
  color: black;
  /*border: 2px solid #4caf50;*/
  border-radius: 8px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px; 
}

.drop-down-container-question-page button:hover {
 /* background-color: #388e3c;*/ /* Yellow background on hover */
}

.drop-down-container-question-page button:active {
 /* background-color: #388e3c;*/ /* Ensure active background matches hover */
}

@media (max-width: 768px) {
  .free-text-container-question-page input, 
  .drop-down-container-question-page select {
    font-size: 14px;
    padding: 10px 20px;
  }

  .free-text-container-question-page button, 
  .drop-down-container-question-page button {
    font-size: 14px;
    padding: 10px 20px;
  }
}

@media (max-width: 480px) {
  .free-text-container-question-page input, 
  .drop-down-container-question-page select {
    font-size: 12px;
    padding: 8px 16px;
  }

  .free-text-container-question-page button, 
  .drop-down-container-question-page button {
    font-size: 12px;
    padding: 8px 16px;
  }
}
