.quiz-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    min-height: 100vh;
    padding: 20px;
    box-sizing: border-box;
    overflow-y: auto;
}

.congratulations-message {
    margin-top: 200px; /* Adjust this value as needed to move the message down */
}

/* Responsive styles */
@media (max-width: 768px) {
    .quiz-container {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .video-style {
        width: 15%;
    }
}

.quiz-question-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    position: relative;
}

.video-style {
    position: absolute;
    top: calc(100% - 250px); /* Adjust based on the height of the question options */
    right: 1rem;
    width: 25%;
    height: auto;
    object-fit: cover;
    transform: translateY(-100%);
}

.question-image-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.question-image {
    max-width: 80%;
    height: auto;
    max-height: 400px; /* Increased max-height to make the image bigger */
}

.question-text-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    text-align: center;
    padding: 0 20px;
    font-size: 1.5em; /* Made the text bigger */
    font-weight: bold; /* Made the text bolder */
}

.answers-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
}

.answers-container button {
    margin: 5px;
    flex: 1 1 calc(50% - 10px);
    box-sizing: border-box;
}

.answers-container button:nth-child(odd):last-child {
    flex: 1 1 calc(50% - 10px); /* Ensure the last button has the same width */
}

@media (max-width: 768px) {
    .answers-container button {
        flex: 1 1 calc(100% - 10px);
    }
}

.start-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /*height: 200vh;*/ /* Reduce height to move it further up */
    text-align: center;
    margin-top: -40vh;  /* Optional: Add padding to move content further up */
}

.start-container p {
    margin-bottom: 10px;
}

.start-container button {
    margin-top: 10px;
}
